.member{
  overflow: hidden;
  transition: height 0.6s ease-in-out, border 0.3s, padding 0.3s;
  width: 100%;
  margin-bottom: 0.25rem;

  input[type="text"]{
    font-size: 1.1rem;
    padding: 0 0.5rem;
  }
}

.memberHeader{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  height: 1.8rem;

  input[type="checkbox"]{
    flex: 0 0 1.5rem;

    & ~ input[type="text"]{
      color: var(--lowlight-one);
    }

    &:checked ~ input[type="text"]{
      color: inherit;
    }
  }

  input[type="text"]{
    flex-grow: 1;
    height: 1.6rem;
    line-height: 1.6rem;
  }

  button{
    height: 1.1rem;
    width: 1.1rem;
    margin-left: 0.5rem;
  }
}

.buttonsCont{
  flex: 0 0 auto;
}

.editMember{
  display: inline-flex;
  flex-basis: 100%;
  flex-direction: column;
  padding-top: 1rem;
  align-items: stretch;
  width: 100%;

  label{
    margin-top: 1rem;
    font-size: 0.8rem;
  }

  input[type="text"]{
    padding: 0.25rem 0;
    border-bottom: 1px solid var(--highlight-one);
    font-weight: 400;
    font-size: 0.9rem;
  }


}
