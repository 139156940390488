body{
  --header-height: 2.5rem;
  background: var(--gunmetal);
  color: #fff;
}

header{
  background: rgba(0,0,0,0.2);
}

main{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: calc(100vh - var(--header-height));
}

a{
  transition: 0.3s all;
  color: #fff;
  &:visited{
    color: #fff;
  }
}

button{
  background: transparent;
  color: #fff;
  transition: all 0.3s;

  &:hover{
    cursor: pointer;
  }

  &:disabled{
    pointer-events: none;
  }

  >svg{
    width: 100%;
    height: 100%;
    object-fit: contain;

    path, line, polyline, circle, rect{
      transition: 0.3s all;
    }
  }
}

button:focus, input:focus,
input[type="checkbox"]:focus + label:before{
  outline: 0;
  box-shadow: 0 0 3px var(--highlight-one);
}

input[type="text"]{
  background: transparent;
  color: #fff;
  font-weight: 700;
  border: 2px solid var(--background-color);
  outline: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover{
    cursor: pointer;
    color: var(--highlight-one);
  }
  &:focus{
    color: var(--highlight-one);
    border: 2px solid var(--highlight-one);
    box-shadow: none;
  }
}

//checkboxes
input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  width: 1rem;
  height: 1rem;
  margin-right: -0.5rem;

}

input[type="checkbox"]+label {
  font-size: 1em;
  line-height: 1.2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  padding: 0.5em 0;

  &:before {
    content: "";
    border: 1px solid var(--pewter-blue);
    width: 1.2em;
    min-width: 1.2em;
    height: 1.2em;
    margin-right: 1em;
    background: transparent;
    transition: 0.5s all;
  }
}

input[type="checkbox"]:checked+label {
  &:before {
    border: 1px solid var(--highlight-one);
    content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 100 100"><path class="stroke" d="M10 50 L40 80 L90 20" fill-opacity="0" stroke="%23FF521B" stroke-width="16" stroke-dasharray="200"><animate attributeType="CSS" attributeName="stroke-dashoffset" from="200" to="0" dur="500ms" /></path></svg>');
  }
}
