.form{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 1rem 0 3rem 0;
  width: 100%;
  max-width: 26rem;

  label{
    margin: 1rem 0;
    font-size: 0.9em;
  }

  hr{
    width: 100%;
    margin: 2rem 0;
    background: var(--lowlight-one);
    border: 0;
    height: 1px;
  }
}

input{
  font-size: calc(1rem + (1.1 - 1) * (100vw - 18rem) / (94 - 18));
}

input[type="text"]{
  min-width: 14rem;
}

.multiInput{
  padding: 0.5rem 0;
  width: 100%;

  >div{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    flex-wrap: nowrap;
    padding: 0.5rem 0;

    >input{
      flex-basis: 12rem;
      flex-grow: 1;
      padding: 0.25rem;
      border-bottom: 1px solid var(--highlight-one);
      font-weight: 400;
    }

    >button{
      flex-basis: 1.2rem;
      margin-left: 0.5rem
    }
  }

  >button{
    font-size: 0.9em;
    margin-top: 1rem;
  }

  input[type="text"]{
    font-size: calc(0.9rem + (1.1 - 0.9) * (100vw - 18rem) / (94 - 18));
  }
}

.rangeInputCont{
  padding: 0.5rem 0;

  >p{
    font-size: 0.8em;
    margin: 0;
    padding: 0;
  }
}

.iconButton{
  width: 3rem;
  height: 3rem;
  margin: 0 0.25em;
  
  &:hover{
    >svg{
      stroke: var(--highlight-one);
    }
  }

  &:disabled{
    >svg{
      stroke: var(--lowlight-one);
    }
  }
}
.textButton{
  background: var(--gradient-one);
  font-size: 1.5rem;
  padding: 0.5rem 1rem;
  font-weight: 700;
  text-transform: uppercase;
  transition: all 0.3s;
  color: #fff;
  text-decoration:none;

  &:hover{
    color: var(--background-color);
    cursor: pointer;
    background: var(--vanilla);
    color: var(--background-color);

    >svg{
      stroke: var(--background-color);
    }
  }
}
