.header{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  height: var(--header-height);
  padding: 0 1rem;
}

.icon{
  height: 1.6rem;
  width: 1.6rem;
  display: inline-block;
  margin-left: 0.5rem;

  >svg{
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: 100%;
    path, circle{
      transition: all 0.3s;
    }
  }

  &:hover{
    >svg{
      path, circle{
        stroke: var(--highlight-one);
        &.fill{
          fill: var(--highlight-one);
        }
      }
    }
  }
}
