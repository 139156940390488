:root{
  --rhino-blue: #37456B;
  --watermelon-red: #fe5668;
  --topaz: #ffc368;
  --pewter-blue: #8DA7BE;

  --eerie-black: #101020;
  --gunmetal: #2C2C33;
  --vanilla: #F2F3AE;
  --flax: #EDD382;
  --neon-carrot: #FC9E4F;
  --giants-orange: #FF521B;

  --background-color: var(--gunmetal);
  --highlight-one: var(--giants-orange);
  --lowlight-one: var(--pewter-blue);
  --gradient-one: linear-gradient(60deg, var(--giants-orange), var(--watermelon-red));

  --flash-one: var(--watermelon-red);
  --flash-two: var(--neon-carrot);
  --flash-three: var(--giants-orange);
}
